import { useEffect} from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap/dist/react-bootstrap.js";
import './App.css';

import backgroundImage from './images/fireworks_5.jpg'

function App() {
  return (
    <>
    
    <section style={{backgroundSize: '100% 100%' , backgroundRepeat: 'no-repeat',  backgroundImage: `url(${ backgroundImage })`}}>

    <Container className='cover-container' style={{height: '100vh'}} >



        <Row className='py-3 text-center'>
          <Col><h1>Danny Family Bonfire & Fireworks</h1>
            
            <Button href='https://buytickets.at/hurstpierpointgymnasticsclub/1381125' target='_BLANK'>Book Tickets</Button>
          </Col>
          

        </Row>
        <Row className='text-center px-3'>
          <Col>
            <p>Hurst Family Fireworks @ Danny Park 2024!</p>
            <p>Get ready for an explosive evening of family fun at the 27th Annual Family Fireworks Event in the grounds of Danny House, organised by Hurstpierpoint Gymnastics Club
            <br />Join us on the 3rd of November, 2024, for a night to remember!</p>
          </Col>
        </Row>
          <Row className='px-4'>
            <Col md={6}>
              <p>🌟 Supporting Our Community</p>
              <p>Every penny raised goes directly towards benefiting the local community. Your contribution will support Oldland Windmill, Albourne, Birchwood Grove, London Mead, Southway, Hassocks Infant School, St. Lawrence CE Primary and Hurstpierpoint Gym Club.</p>
              <p>Together, we can make a lasting impact!</p>
            </Col>
            <Col md={6}>
              <p>🍔 Delicious BBQ by Hassocks Pub</p>
              <p>Indulge in mouthwatering treats from the renowned Hassocks Pub, serving up a delectable BBQ spread that's sure to satisfy every palate. It's a feast fit for fireworks!</p>
            </Col>
          </Row>

          <Row className='px-4'>
            <Col  md={6}>
              <p>🎟️ Ticket Information</p>
              <p>Family Ticket: £15 (Admits 2 adults & 3 children)
              <br />General Admission Adult/Child Ticket: £7
              <br />Under 5's free</p>
              <br />Please note there will be a £1.00 booking fee to cover the site fees.
              <br /><strong>To support your local good cause and to receive a 10% discount, please use the code that represents your group below</strong>
              <Table>
                <tr>
                  <td>Name</td>
                  <td>Code</td>
                </tr>
                <tr><td>Albourne	</td><td>AL10</td></tr>
                <tr><td>Birchwood Grove	</td><td>BG10</td></tr>
                <tr><td>Hassocks Infants	</td><td>HI10</td></tr>
                <tr><td>Hurstpierpoint Gym Club	</td><td>HG10</td></tr>
                <tr><td>London Meed	</td><td>LM10</td></tr>
                <tr><td>Oldland Mill	</td><td>OM10</td></tr>
                <tr><td>Southway	</td><td>SO10</td></tr>
                <tr><td>St. Lawrence School	</td><td>SL10</td></tr>
              </Table>
              

            </Col>
            <Col  md={6}>
              <p>🕟 Event Details</p>
              <p><strong>Gates open at 4:00pm, so come early and grab a prime spot!</strong>
              <br />The mesmerising fireworks display begins at 5:30pm, promising a breathtaking show that will light up the night. Provided by Aurora Fireworks
              <br />Bonfire by Oldland Windmill follows.
              <br />Danny Park, New Way Lane, BN6 9BB</p>
            </Col>
            
          </Row>
          
          <Row className='text-center px-4'>
            <Col>
                <p>🚗 Parking Information</p>
                <p>PARKING HAS SADLY BEEN CANCELLED DUE TO CHANGE IN GROUND CONDITIONS.
                <br />Limited Parking will be made available for "Registered Blue Badge Holders" please show badge on entry</p>
                <p>🎟️ Get Your Tickets Now!</p>
                <p>Don't miss out on an evening of dazzling lights, delectable bites, and a whole lot of family fun! 
                <br />Let's make the 27th year of the Family Fireworks @ Danny House the best one yet. See you there!
                <br />Danny Park, New Way Lane, BN6 9BB
                <br /><Button href='https://buytickets.at/hurstpierpointgymnasticsclub/1381125' target='_BLANK' className='my-2'>Book Tickets</Button>
                </p>
          </Col>
        </Row>


    </Container>
    </section>
    </>
  );
}

export default App;
